<template>
  <div>
    <b-row class="mt-1">
      <b-col
        cols="12"
        md="7"
        class="mb-md-0 mb-2"
      >
        <v-select
          v-model="reportFilters.aquifersType"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          placeholder="Aquifers"
          :options="filterAquifersTypeOptions"
          class="w-100"
          @input="
            option => (filters.aquifersType = option ? option.value : null)
          "
        />
      </b-col>
      <b-col
        cols="12"
        md="3"
        class="mb-md-0 mb-2"
      >
        <date-picker
          :mode="mode"
          @date-picked="datePicked"
        />
      </b-col>
      <b-col
        cols="12"
        md="2"
        class="mb-md-0 mb-2"
      >
        <div class="flex justify-center gap-1">
          <b-dropdown
            v-if="$can('download', 'reports')"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            text="Generate"
            variant="primary"
            :disabled="disableGenerate"
            split
            right
            @click="emitGenerate()"
          >
            <b-dropdown-item
              :disabled="disableGenerate"
              @click="emitPDF()"
            >
              PDF
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="disableGenerate"
              @click="emitExcel()"
            >
              Excel
            </b-dropdown-item>
          </b-dropdown>
          <b-button
            v-else
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            :disabled="disableGenerate"
            @click="emitGenerate()"
          >
            Generate
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import _ from 'lodash'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import DatePicker from '@leecom/date-picker/DatePicker.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    vSelect,
    FeatherIcon,
    DatePicker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      STORE_MODULE_NAME: 'app-reports-consumption-aquifers-wise',
      reportFilters: {
        aquifersType: null,
      },
      rangeDate: null,
      filters: {
        aquifersType: null,
      },
      disableGenerate: true,
    }
  },
  computed: {
    mode() {
      return this.$store.state[this.STORE_MODULE_NAME].mode
    },
    filterAquifersTypeOptions() {
      const options = this.$store.state.app.enums
        ? _.cloneDeep(this.$store.state.app.enums.AquifersType)
        : []

      if (_.find(options, { key: 'ALL' }) == undefined) {
        options.unshift({
          key: 'ALL',
          label: 'All',
          value: 'all',
        })
      }

      return options
    },
  },
  watch: {
    mode() {
      this.reportFilters = {
        aquifersType: null,
      }
      this.rangeDate = null
      this.filters = {
        aquifersType: null,
      }
      this.canGenerate()
      this.resetActionData()
    },
    reportFilters: {
      handler() {
        this.canGenerate()
        this.resetActionData()
      },
      deep: true,
    },
  },
  methods: {
    datePicked(date) {
      this.rangeDate = date
      this.canGenerate()
      this.resetActionData()
    },
    canGenerate() {
      if (this.waiting) {
        this.disableGenerate = false
        return this.disableGenerate
      }

      this.disableGenerate = this.reportFilters.aquifersType === null || this.rangeDate === null

      return this.disableGenerate
    },
    setActionData() {
      const payload = {
        filters: this.filters,
        rangeDate: this.rangeDate,
      }
      this.$store.dispatch(`${this.STORE_MODULE_NAME}/setActionData`, payload)
    },
    resetActionData() {
      this.$store.dispatch(`${this.STORE_MODULE_NAME}/resetData`)
    },
    emitGenerate() {
      this.setActionData()
      const payload = {
        filters: this.filters,
        rangeDate: this.rangeDate,
      }
      this.$emit('generate', payload)
    },
    emitPDF() {
      this.setActionData()
      const payload = {
        filters: this.filters,
        rangeDate: this.rangeDate,
      }
      this.$emit('open-pdf', payload)
    },
    emitExcel() {
      this.setActionData()
      const payload = {
        filters: this.filters,
        rangeDate: this.rangeDate,
      }
      this.$emit('download-excel', payload)
    },
  },
}
</script>
